<template>
  <div>
    <el-breadcrumb style="margin:0 0 6px 6px;" separator="/">
        <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
        <el-breadcrumb-item>常规管理</el-breadcrumb-item>
        <el-breadcrumb-item>附件管理</el-breadcrumb-item>
      </el-breadcrumb>
    <div class="content">
      <div class="list_box">
        <div class="tt1">附件管理列表</div>
      </div>
      <template>
        <el-table :header-cell-style="tableHeader" :data="indexList" style="width: 100%" stripe>
          <el-table-column width="60px" align="center" prop="id" label="ID号"></el-table-column>
          <el-table-column min-width="90px" align="center" label="预览">
              <template v-slot="scope">
                  <img style="width: 60px;height: 60px;" :src="URL_img + scope.row.url" alt="">
              </template>

          </el-table-column>
          
          <el-table-column min-width="220px" align="center" prop="url" label="物理路径"></el-table-column>
           <el-table-column width="80px" align="center" prop="imagewidth" label="宽度"></el-table-column>
          <el-table-column align="center" prop="imageheight" label="高度"></el-table-column>
          <el-table-column align="center" prop="imagetype" label="图片类型"></el-table-column>
         <el-table-column align="center" prop="filesize" label="文件大小"></el-table-column>
         <el-table-column min-width="160px" align="center" prop="createtime" label="创建日期">
             <template v-slot="scope">
                 {{scope.row.createtime | dateFormat}}
             </template>
         </el-table-column>
          <el-table-column width="100px" align="center" label="操作">
            <template v-slot="scope">
              <el-tooltip :enterable="false" effect="dark" content="删除" placement="top-end">
                <el-button
                  size="mini"
                  type="danger"
                  icon="iconfont icon-shanchu"
                  @click="del(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[9, 10, 15, 20]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 9,
      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333'
      },
      URL_img: ''
     
    }
  },
  mounted() {
      this.URL_img = this.$URL_img
    this.index()
  },
  methods: {
    handleSizeChange(val) {
      this.pageNumber = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
   
     // 附件管理列表
    async index() {
      const { data: res } = await this.$http.get('edulogin.php/general/attachment/index', {
        params: {
          page: this.page,
          limit: this.limit
        }
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },
   
    // 查询
    chaxun() {
      this.page = 1
      this.warehousing()
    },
    // 删除
    del(id) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            'edulogin.php/general/attachment/del',
            {
              params: {
                ids: id
              }
            }
          )
          if (res.code != 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.index()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
   
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    padding: 15px 1px;
  }
  .int_width {
    width: 242px;
    margin-right: 100px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
}
</style>
