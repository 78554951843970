<template>
  <div>
    <div class="content">
      <div class="tt">资料修改</div>
      <div class="xian"></div>
      <el-form ref="form" :model="addform" :rules="rules" label-width="160px">
        <el-form-item label="姓名：" prop="nickname">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.nickname"
            placeholder="请输入姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="头像：" prop="avatar">
          <el-upload
            class="avatar-uploader"
            :action="URL + 'edulogin.php/ajax/upload'"
            :show-file-list="false"
            :with-credentials="true"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="addform.avatar"
              :src="URL_img + addform.avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.email"
            placeholder="请输入邮箱"
            clearable
          ></el-input>
          <!-- <el-button style="margin-left:10px" size="mini" type="warning" @click="editemail">修改邮箱</el-button> -->
        </el-form-item>
        <el-form-item label="密码：">
          <el-input
            size="small"
            type="password"
            style="width: 450px"
            v-model="addform.password"
            placeholder="请输入密码"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="省：" prop="province">
            <el-select @change="selectProvice" size="small" style="width:450px;" v-model="addform.province" clearable placeholder="请选择">
              <el-option
                v-for="item in provinceList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="市：" prop="city">
            <el-select size="small" style="width:450px;" v-model="addform.city" clearable placeholder="请选择">
              <el-option
                v-for="item in cityList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item> -->
      </el-form>
      <div class="bm_box">
        <el-button type="cha" class="add_btn" @click="add">提交</el-button>
      </div>
      <el-dialog
        title="修改邮箱"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="handleClose"
      >
        <el-form ref="form1" :model="form" label-width="80px" :rules="rules1">
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="form.email"
              style="width: 450px"
              size="small"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-input
              v-model="form.captcha"
              style="width: 350px"
              size="small"
              clearable
            ></el-input>
            <el-button
              style="margin-left: 10px"
              size="mini"
              type="warning"
              @click="send"
              >{{ code }}</el-button
            >
          </el-form-item>
        </el-form>
        <el-button type="cha" class="add_btn" @click="editemail_btn"
          >确认</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkEmail = (rule, value, cb) => {
      const regMobile =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      addform: {
        nickname: '',
        avatar: '',
        email: '',
        password: '',
        // province: '',
        // city: ''
      },

      rules: {
        nickname: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        avatar: [{ required: true, message: '请选择头像', trigger: 'blur' }],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        province: [{ required: true, message: '请选择省', trigger: 'blur' }],
        city: [{ required: true, message: '请选择市', trigger: 'blur' }],
      },
      URL: '',
      token: '',
      URL_img: '',
      provinceList: [],
      cityList: [],
      // 修改邮箱
      dialogVisible: false,
      form: {
        email: '',
        captcha: '',
      },
      code: '发送验证码',
      rules1: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
    }
  },
  mounted() {
    this.URL = this.$URL
    this.URL_img = this.$URL_img
    this.getInfo()
    // this.province()
    this.__token__()
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.$refs.form1.resetFields()
    },
    // 修改邮箱弹出框
    editemail() {
      this.dialogVisible = true
    },
    // 发送验证码
    async send() {
      if (this.code != '获取验证码') {
        return
      }
      let email =
        /^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/
      if (!this.form.email) {
        this.$message.error('请输入您的邮箱')
        return false
      }
      if (this.form.email && !email.test(this.form.email)) {
        this.$message.error('请输入您的合法邮箱')
        return false
      }
      const { data: res } = await this.$http.post('index.php/api/ems/send', {
        email: this.form.email,
        event: 'modifyemail',
      })
      if (res.code != 1) {
        return this.$message.error(res.msg)
      }
      this.$message.success('验证码已发送，请登录邮箱查看')
      let num = 60
      this.code = num + 's'
      let setIn = setInterval(() => {
        if (num > 0) {
          --num
          this.code = num + 's'
        } else {
          clearInterval(setIn)
          this.code = '获取验证码'
        }
      }, 1000)
    },
    //   修改邮箱修改邮箱 /edulogin.php/general/profile/modifyemail
    editemail_btn() {
      this.$refs.form1.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据!')
        const { data: res } = await this.$http.post(
          'edulogin.php/general/profile/modifyemail',
          this.form
        )
        if (res.code != 1) {
          return this.$message.error(res.msg)
        }
        this.dialogVisible = false
        this.$refs.form1.resetFields()
        this.$message.success(res.msg)
      })
    },
    // 获取登录信息
    async getInfo() {
      const { data: res } = await this.$http.get(
        'edulogin.php/general/profile/info'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.addform = res.data
      if (res.data.province) {
        this.city()
      }
    },
    handleAvatarSuccess(res, file) {
      this.addform.avatar = res.data.url
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },

    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 省
    async province() {
      const { data: res } = await this.$http.get('index.php/api/common/area', {
        params: {
          province: null,
        },
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.provinceList = res.data
    },
    // 市
    async city() {
      const { data: res } = await this.$http.get('index.php/api/common/area', {
        params: {
          province: this.addform.province,
        },
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.cityList = res.data
    },
    selectProvice() {
      if (this.addform.province) {
        this.addform.city = ''
        this.city()
      } else {
        this.addform.city = ''
        this.cityList = []
      }
    },
    //     更新个人信息
    add() {
      // window.open(this.$URL + 'zdadmin.php/sale/delivery/exportlist?preview=2')
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据!')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/general/profile/update',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .tt {
    font-size: 20px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 20px auto 20px;
  }
  .bm_box {
    width: 100%;
    //   text-align: center;
  }
  .add_btn {
    display: block;
    margin: 40px 0 40px 160px;
  }
  .avatar-uploader-icon[data-v-244aa556] {
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
