<template>
  <div>
    <div class="content">
      <div class="tt">平台银行信息</div>
      <div class="xian"></div>
      <el-form ref="form" :model="addform" :rules="rules" label-width="160px">
        <el-form-item label="银行账户：" prop="bank_account_name">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.bank_account_name"
            placeholder="请输入银行账户"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账号：" prop="bank_no">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.bank_no"
            placeholder="请输入银行账号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="银行名称：" prop="bank_name">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.bank_name"
            placeholder="请输入银行名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="支付码图片：" prop="pay_pic">
          <el-upload
            class="avatar-uploader"
            :action="URL + 'edulogin.php/ajax/upload'"
            :show-file-list="false"
            :with-credentials="true"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
          >
            <img
              v-if="addform.pay_pic"
              :src="URL_img + addform.pay_pic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="bm_box">
        <el-button type="cha" class="add_btn" @click="add">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addform: {
        bank_account_name: '',
        bank_no: '',
        bank_name: '',
        pay_pic: '',
      },

      rules: {
        bank_account_name: [{ required: true, message: '请输入银行账户名称', trigger: 'blur' }],
        pay_pic: [{ required: true, message: '请选择支付码图片', trigger: 'blur' }],
        bank_no: [
          { required: true, message: '请输入银行账号', trigger: 'blur' }
        ],
        bank_name: [{ required: true, message: '请输入银行名称', trigger: 'blur' }]
      },
      URL: '',
      token: '',
      URL_img: ''
    }
  },
  mounted() {
    this.URL = this.$URL
    this.URL_img = this.$URL_img
    this.index()
    this.__token__()
  },
  methods: {
    // 获取系统信息
    async index() {
      const { data: res } = await this.$http.get(
        'edulogin.php/general/config/index'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.addform = {
        bank_account_name: res.data.basic.list.bank_account_name.value,
        bank_no: res.data.basic.list.bank_no.value,
        bank_name: res.data.basic.list.bank_name.value,
        pay_pic: res.data.basic.list.pay_pic.value,
      }
    },

    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    //    更新支付银行信息 /edulogin.php/general/config/editbank
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据!')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/general/config/editbank',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.__token__()
        this.$message.success(res.msg)
      })
    },
    handleAvatarSuccess(res, file) {
      this.addform.pay_pic = res.data.url
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .tt {
    font-size: 20px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 20px auto 20px;
  }
  .bm_box {
    width: 100%;
    //   text-align: center;
  }
  .add_btn {
    display: block;
    margin: 40px 0 40px 160px;
  }
  .avatar-uploader-icon[data-v-244aa556] {
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
