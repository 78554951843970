<template>
  <div>
    <div class="content">
      <div class="tt">系统资料配置</div>
      <div class="xian"></div>
      <el-form ref="form" :model="addform" :rules="rules" label-width="160px">
        <el-form-item label="站点名称：" prop="site_name">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.site_name"
            placeholder="请输入站点名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="备案号：" prop="beian">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.beian"
            placeholder="请输入备案号"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="SMTP服务器：" prop="mail_smtp_host">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.mail_smtp_host"
            placeholder="请输入SMTP服务器"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="SMTP端口：" prop="mail_smtp_port">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.mail_smtp_port"
            placeholder="不加密默认25,SSL默认465,TLS默认587"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="SMTP用户名：" prop="mail_smtp_user">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.mail_smtp_user"
            placeholder="请输入SMTP用户名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="SMTP密码：" prop="mail_smtp_pass">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.mail_smtp_pass"
            placeholder="请输入SMTP密码"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="发件人邮箱：" prop="mail_from">
          <el-input
            size="small"
            style="width: 450px"
            v-model="addform.mail_from"
            placeholder="请输入发件人邮箱"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="邮件发送方式：">
          <el-input
            disabled
            size="small"
            style="width: 450px"
            v-model="mail_type"
            placeholder="请输入邮件发送方式"
            clearable
          ></el-input>
        </el-form-item>
        
        <el-form-item label="SMTP验证方式：">
          <el-input
            disabled
            size="small"
            style="width: 450px"
            v-model="mail_verify_type"
            placeholder="（SMTP验证方式[推荐SSL]）"
            clearable
          ></el-input>
        </el-form-item> -->
      </el-form>
      <div class="bm_box">
        <el-button type="cha" class="add_btn" @click="add">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkEmail = (rule, value, cb) => {
      const regMobile =
        /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的邮箱'))
    }
    return {
      addform: {
        site_name: '',
        beian: '',
        // mail_type: '',
        // mail_smtp_host: '',
        // mail_smtp_port: '',
        // mail_smtp_user: '',
        // mail_smtp_pass: '',
        // mail_verify_type: '',
        // mail_from: '',
      },

      rules: {
        site_name: [
          { required: true, message: '请输入站点名称', trigger: 'blur' },
        ],
        beian: [{ required: true, message: '请输入备案名称', trigger: 'blur' }],
        mail_smtp_host: [{ required: true, message: '请输入SMTP服务器', trigger: 'blur' }],
        mail_smtp_port: [{ required: true, message: '请输入SMTP端口', trigger: 'blur' }],
        mail_smtp_user: [{ required: true, message: '请输入SMTP用户名', trigger: 'blur' }],
        mail_smtp_pass: [{ required: true, message: '请输入SMTP密码', trigger: 'blur' }],
        mail_from: [{ required: true, message: '请输入发件人邮箱', trigger: 'blur' }]
      },

      token: '',
      mail_type: '',
      mail_verify_type: ''
    }
  },
  mounted() {
    this.index()
    this.__token__()
  },
  methods: {
    // 获取系统信息
    async index() {
      const { data: res } = await this.$http.get(
        'edulogin.php/general/config/index'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.addform = {
        site_name: res.data.basic.list[0].value,
        beian: res.data.basic.list[1].value,
        // mail_type: res.data.email.list.mail_type.value[0],
        // mail_smtp_host: res.data.email.list.mail_smtp_host.value,
        // mail_smtp_port: res.data.email.list.mail_smtp_port.value,
        // mail_smtp_user: res.data.email.list.mail_smtp_user.value,
        // mail_smtp_pass: res.data.email.list.mail_smtp_pass.value,
        // mail_verify_type: res.data.email.list.mail_verify_type.value[0],
        // mail_from: res.data.email.list.mail_from.value,
      }
      // console.log(res.data.email.list.mail_type.value)
      // this.mail_type = res.data.email.list.mail_type.content[+(res.data.email.list.mail_type.value[0])]
      // this.mail_verify_type = res.data.email.list.mail_verify_type.content[+(res.data.email.list.mail_verify_type.value[0])]
      // console.log(44,this.mail_type,this.mail_verify_type)
    },

    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    //    更新系统信息
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整数据!')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/general/config/edit',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.__token__()
        this.$message.success(res.msg)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .tt {
    font-size: 20px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 20px auto 20px;
  }
  .bm_box {
    width: 100%;
    //   text-align: center;
  }
  .add_btn {
    display: block;
    margin: 40px 0 40px 160px;
  }
  .avatar-uploader-icon[data-v-244aa556] {
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    border: 1px dashed #d9d9d9 !important;
  }
}
</style>
